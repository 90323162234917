import styled from 'styled-components';

export const Container = styled.div`
  min-height: 50px;
  padding-left: 20px;
  padding-right: 20px;
`;

export const Content = styled.div`
  max-width: 765px;
  width: 100%;
  margin: 0 auto;
`;
