import React from 'react';
import * as S from './Container1.styles';

const Container1 = ({ width, children }) => {
  const maxWidth = width ? width : 765;
  return (
    <S.Container>
      <S.Content style={{ maxWidth: maxWidth }}>{children}</S.Content>
    </S.Container>
  );
};

export default Container1;
